:root {
    --primary-color: #ffe600;
    --secondary-color: #333;
    --success-color: #07a871;
    --body-color: #666;
    --muted-color: #999;
    --dark-color: #181818;
    --light-color: #f0f0f0;
}

html {
    @media (max-width: 576px) {
        font-size: 14px;
    }
    @media (min-width: 1400px) {
        font-size: 20px;
    }
}
main > * {
    display: block;
    width: 100%;
}
a,
a:link {
    color: var(--dark-color);
}
h1 {
    color: var(--secondary-color);
}
@media (max-width: 576px) {
    h2 {
        font-size: 1.5rem;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    & + hr {
        display: inline-block;
        border-top-color: var(--muted-color);
        width: 6.25rem;
        & + p.text-muted {
            text-align: center;
        }
    }
}
button {
    appearance: none;
    outline: none;
    border: none;
    background: transparent;
}
input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.text-primary {
    color: var(--primary-color) !important;
}
.text-muted {
    color: var(--muted-color) !important;
}
.bg-primary {
    background-color: var(--primary-color) !important;
    color: var(--dark-color) !important;
}
.bg-dark {
    background-color: var(--dark-color) !important;
    color: white !important;
    a:not(.dropdown-item),
    a:not(.dropdown-item):link,
    .nav .dropdown-toggle.btn {
        color: white !important;
        &::after {
            content: none;
        }
    }
}
.border-top,
.border-right {
    border-color: var(--light-color) !important;
}

.container.card-deck {
    justify-content: space-between;
    margin: auto;
}
@media (max-width: 768px) {
    .card-deck > .card {
        min-width: 100%;
        max-width: 100%;
    }
}
.card-footer {
    border: none;
    background: transparent;
}
.btn-primary {
    border-color: var(--primary-color) !important;
    &:disabled {
        border-color: var(--secondary-color) !important;
        background: var(--secondary-color) !important;
        color: white !important;
    }
}
.btn-outline-secondary {
    border-color: var(--muted-color);
    color: var(--muted-color) !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--success-color);
    background-color: var(--success-color);
}
form .btn {
    min-height: 3rem;
}
.form-control,
.custom-control {
    border: none;
    background: var(--light-color);
    padding: 1rem;
    min-height: 3rem;
    font-size: small;
    &::placeholder {
        color: var(--muted-color);
    }
}
.form-group drop-menu {
    display: block !important;
    & > .btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--light-color);
        width: 100%;
    }
}
.back-image {
    min-height: 50vh !important;
}
.narrow-box {
    margin: auto;
    max-width: 60rem;
}
@media (min-width: 1367px) {
    .container {
        max-width: 100rem;
    }
    .narrow-box {
        max-width: 89rem;
    }
}
@media (max-width: 576px) {
    .banner {
        min-height: 25vh !important;
    }
}

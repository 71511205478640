:root {
  --primary-color: #ffe600;
  --secondary-color: #333;
  --success-color: #07a871;
  --body-color: #666;
  --muted-color: #999;
  --dark-color: #181818;
  --light-color: #f0f0f0;
}

@media (width <= 576px) {
  html {
    font-size: 14px;
  }
}

@media (width >= 1400px) {
  html {
    font-size: 20px;
  }
}

main > * {
  width: 100%;
  display: block;
}

a, a:link {
  color: var(--dark-color);
}

h1 {
  color: var(--secondary-color);
}

@media (width <= 576px) {
  h2 {
    font-size: 1.5rem;
  }
}

h1 + hr, h2 + hr, h3 + hr, h4 + hr, h5 + hr, h6 + hr {
  border-top-color: var(--muted-color);
  width: 6.25rem;
  display: inline-block;
}

h1 + hr + p.text-muted, h2 + hr + p.text-muted, h3 + hr + p.text-muted, h4 + hr + p.text-muted, h5 + hr + p.text-muted, h6 + hr + p.text-muted {
  text-align: center;
}

button {
  appearance: none;
  background: none;
  border: none;
  outline: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-muted {
  color: var(--muted-color) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
  color: var(--dark-color) !important;
}

.bg-dark {
  background-color: var(--dark-color) !important;
  color: #fff !important;
}

.bg-dark a:not(.dropdown-item), .bg-dark a:not(.dropdown-item):link, .bg-dark .nav .dropdown-toggle.btn {
  color: #fff !important;
}

.bg-dark a:not(.dropdown-item):after, .bg-dark a:not(.dropdown-item):link:after, .bg-dark .nav .dropdown-toggle.btn:after {
  content: none;
}

.border-top, .border-right {
  border-color: var(--light-color) !important;
}

.container.card-deck {
  justify-content: space-between;
  margin: auto;
}

@media (width <= 768px) {
  .card-deck > .card {
    min-width: 100%;
    max-width: 100%;
  }
}

.card-footer {
  background: none;
  border: none;
}

.btn-primary {
  border-color: var(--primary-color) !important;
}

.btn-primary:disabled {
  border-color: var(--secondary-color) !important;
  background: var(--secondary-color) !important;
  color: #fff !important;
}

.btn-outline-secondary {
  border-color: var(--muted-color);
  color: var(--muted-color) !important;
}

.custom-control-input:checked ~ .custom-control-label:before {
  border-color: var(--success-color);
  background-color: var(--success-color);
}

form .btn {
  min-height: 3rem;
}

.form-control, .custom-control {
  background: var(--light-color);
  border: none;
  min-height: 3rem;
  padding: 1rem;
  font-size: small;
}

.form-control::placeholder, .custom-control::placeholder {
  color: var(--muted-color);
}

.form-group drop-menu {
  display: block !important;
}

.form-group drop-menu > .btn {
  background: var(--light-color);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.back-image {
  min-height: 50vh !important;
}

.narrow-box {
  max-width: 60rem;
  margin: auto;
}

@media (width >= 1367px) {
  .container {
    max-width: 100rem;
  }

  .narrow-box {
    max-width: 89rem;
  }
}

@media (width <= 576px) {
  .banner {
    min-height: 25vh !important;
  }
}
/*# sourceMappingURL=index.1428257a.css.map */
